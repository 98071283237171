<template>
  <div class="flex flex-col items-center justify-center">
    <ResponseCard class="my-auto" :message="message"></ResponseCard>
  </div>
</template>

<script>
import ResponseCard from "@/components/ResponseCard";

export default {
  name: 'request-demo',
  components: {ResponseCard},
  data() {
    return {
      message: "",
    };
  },
  mounted() {
    this.$api.get('test')
    .then(res => {
      this.message = res.data;
    })
    .catch(e => {
      console.log(e)
    })
  }
}
</script>