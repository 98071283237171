<template>
  <div class="response-card flex flex-col items-center justify-center font-bold text-lg shadow">
    <span class="mb-4">This message is coming from a spring endpoint:</span>
    <span class="text-xl">{{ message }}</span>
  </div>
</template>

<script>
export default {
  name: "ResponseCard",
  props: {
    message: {
      type: String,
      required: true,
    }
  }
}
</script>

<style scoped>
  .response-card {
    width: 100%;
    max-width: 400px;
    height: 200px;
    border-radius: 5px;
    background-color: lightgrey;
  }
</style>